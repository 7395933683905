<template>
  <div id="main" class="main__map">
    <div class="map-zone__controls">
      <div class="container">
        <a href="#" @click.prevent="isFilter=!isFilter">Фільтри</a>
      </div>        
    </div>
    <div class="map-zone"
      :class="{ 'show-filter': isFilter }"
    >      
      <div v-show="!loaded" class="loading">loading......</div>      
      <GoogleMap 
        :markers="markers"
        :hideMarkers="deletedMarkers"
        :idRay="idRay"
        :apiKey="MAPS_API_KEY"
      />
      <MapControls />

    </div><!-- map-zone -->

  </div>
</template>

<style lang="scss">
  @import './src/assets/scss/sections/map.scss';
</style>

<script>
  import axios from 'axios';
  import MapControls from './../../components/body/map-controls.vue';
  import GoogleMap from './../../components/body/google-map.vue';
  import { mapSettings, icons, clusterStyles } from '../../constants/mapSettings';

  export default {
    metaInfo() {
      return {
        titleTemplate: '%s - Траси',
        meta: [
          { name: 'description', content:  'Знайдіть найближчий АЗК ОККО, WOG, Socar, Shell, Parallel, Укрнафта, КЛО або будь-який інший на карті'},
          { property: 'og:title', content: "АЗС України - Карта АЗС"},
          // { property: 'og:site_name', content: 'АЗС України'},
          // {property: 'og:type', content: 'website'},    
          // {name: 'robots', content: 'index,follow'} 
        ]
      }
    },
    name: "Roads",
    data() {
      return {
        loaded: false,
        isFilter: false,
        MAPS_API_KEY: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_MAPS_API_KEY : process.env.VUE_APP_MAPS_API_KEY_DEV,
        zoom: 6,
        center: { lat: 48.634723, lng: 31.247863 },
        deletedMarkers: [],
        mapOptions: mapSettings,
        icons: icons,
        clusterStyles: clusterStyles,
        iconDefault: {
          url: '/assets/images/icons/marker.svg',
          size: {width: 46, height: 46, f: 'px', b: 'px'},
          scaledSize: {width: 46, height: 46, f: 'px', b: 'px'}
        }
      }
    },
    components: { MapControls, GoogleMap },
    created() {
      // console.log('window.innerWidth', window.innerWidth);
    },
    computed: {
      markers() {
        return this.$store.getters.getMarkers;
      },
      azs() {
        const markers = this.markers,
              azs = [];
        markers.forEach(element => {
          azs.push(element.id);
        });
        return azs;
      },
      fuelSelected() {
        return this.$store.getters.getFuelSelected;
      },
      brandsSelected() {
        return this.$store.getters.getSelectedBrands;
      },
      allAZS() {
        return this.$store.getters.getIsAllAZS;
      },
      mapFiltersStr() {
        const fuels = JSON.stringify(this.$store.getters.getFuelSelected),
              brands = JSON.stringify(this.$store.getters.getSelectedBrands),
              allAZS = JSON.stringify(this.$store.getters.getIsAllAZS);
        return fuels + brands + allAZS;
      },
      idRay() {
        return this.$route.query.ray
      }
    },
    watch: {
      mapFiltersStr() {        
       this.updateMapData(this.fuelSelected, this.brandsSelected, this.allAZS);
      }
    },
    mounted() {
      if (this.mapFiltersStr !== '[][]' ) {
        this.updateMapData(this.fuelSelected, this.brandsSelected, this.allAZS);
      }
    },
    methods: {
      getAZS(params) { 
        this.loaded = false;

        let url = `http://azs.uapetrol.com/cgi-bin/_getAZS2.pl`;
            url = `/assets/data/getAZS.json`;
            url = `//dev.uapetrol.com/scripts/getAZS.php`;

        axios
        .post(url, { params: params })
        .then(response => {

          // console.log(response.data);
          // console.log(params);
          this.$store.dispatch('updateMarkers', response.data.data);
          // this.markers = response.data.data          
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loaded = true;
        });
      },

      updateMapData(fuels, brands, allAZS) {
        const params = {fuels: fuels, brands: brands, azs: this.azs, allAZS: allAZS};
        this.getAZS(params);
      }
    }
  }
</script>